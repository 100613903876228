
 * {
	 box-sizing: border-box;
}
 body {
	 margin: 0;
	 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	 -webkit-font-smoothing: antialiased;
	 -moz-osx-font-smoothing: grayscale;
	 background-color: #050311;
	 color: #fff;
}
 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
