.button {
	cursor: pointer;
	font-size: 1em;
	font-weight: 600;
	border: none;
	outline: none;
	transition: filter 100ms linear;
	border-radius: 99999px;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	white-space: nowrap;
}
.button.rounded:disabled {
	background: rgba(255, 255, 255, 0.4) !important;
	color: #fff 99 !important;
	cursor: default;
	filter: none !important;
}
.button.rounded.normal {
	padding: 1rem 2.5rem;
}
.button.rounded.compact {
	padding: 0.5rem 2.5rem;
}
.button.rounded.primary {
	background: linear-gradient(#ffd354, #edb72d);
	color: black;
}
.button.rounded.primary:hover {
	filter: brightness(1.08);
}
.button.rounded.primary:active {
	transition: filter 0ms linear;
	background: #ffd354;
}
.button.rounded.secondary {
	background-color: transparent;
	color: white;
	transition: background-color 100ms linear;
}
.button.rounded.secondary::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 99999px;
	padding: 2.5px;
	background: linear-gradient(#9f69e3, #6e22cd);
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	mask-composite: exclude;
}
.button.rounded.secondary:hover {
	background-color: rgba(159, 105, 227, 0.2);
}
.button.rounded.secondary:active {
	transition: filter 0ms linear;
	background: linear-gradient(#9f69e3, #6e22cd);
}
.button.text {
	background-color: transparent;
	color: white;
	font-size: 1.2em;
	font-weight: 900;
	transition: color 100ms linear;
}
.button.text:disabled {
	color: gray !important;
	cursor: default;
	filter: none !important;
}
.button.text.compact {
	font-size: 1em;
}
.button.text.primary:hover,
.button.text.primary.active {
	color: #ffd354;
}
.button.text.secondary:hover,
.button.text.secondary.active {
	color: #9f69e3;
}
.button > span:not(:last-child) {
	margin-right: 0.5rem;
}
.button > span {
	display: flex;
	justify-content: center;
	align-items: center;
}
