.App {
  text-align: center;
  background: url('./assets/galaxy.jpg') center center fixed;
  background-size: cover;
  min-height: 100vh;
}
.steps>ol{
  font-size: large;
  text-align: justify;
}
.steps{
  max-width: 350px;
  margin: auto;
}
